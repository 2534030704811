import React from "react";

const TTC = ({ inputText, isGenerating, text }) => {
  return (
    <>
      <p class="preset-title">Text To Command</p>

      {inputText ? (
        <>
          <p class="text-muted">{inputText}</p>
          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
              {text && (
                <p
                  style={{
                    color: "black",
                  }}
                  class="text- mb-0 text-"
                >
                  {text}
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <p class="text-muted" style={{ fontSize:"14px" }}>Ask Constance if we need some bread</p>
          <p
          style={{ color: "black",fontSize:"14px" }}
          >
            Output: send-msg `find constance` Do we need some bread?
          </p>
        </>
      )}
    </>
  );
};

export default TTC;
