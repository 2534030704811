export const method2Data = [
  {
    id: crypto.randomUUID(),
    title: "Automated Operation",
    description: [
      "Effortless Content Creation: Our AI analyzes your business and generates tailored content, saving you the time and effort of manual content creation.",
      "Scheduled Posting: Content is automatically posted at optimal times, ensuring maximum engagement and reach without requiring constant attention.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Business-Driven Insights",
    description: [
      "Targeted Content: The AI uses in-depth business analysis to understand your audience and industry, creating content that resonates with your target market.",
      "Data-Driven Strategy: Content strategies are based on real-time data and trends, enhancing the relevance and effectiveness of your social media presence.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Flexible Payment Options",
    description: [
      "Monthly Plans: Choose a monthly subscription to maintain flexibility and adjust your usage based on changing needs.",
      "Yearly Plans: Opt for a yearly subscription to benefit from cost savings and a long-term commitment to your social media strategy.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Cost Efficiency",
    description: [
      "Subscription-Based Model: Predictable monthly or yearly payments help in budgeting and financial planning.",
      "Value for Money: Access a comprehensive social media management solution at a fraction of the cost of hiring a full-time social media manager.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Consistency and Reliability",
    description: [
      "Regular Posting: Maintain a consistent posting schedule to keep your audience engaged and informed.",
      "Brand Voice Maintenance: Ensure a consistent brand voice and style across all social media platforms.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Scalability",
    description: [
      "Grow with Your Business: The AI adapts to your business growth, handling increased content needs and expanding your social media presence effortlessly.",
      "Customizable Plans: Choose plans that scale with your business requirements, providing the right level of service as you grow.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Enhanced Engagement",
    description: [
      "Optimized Content: AI-generated content is designed to maximize engagement, with posts tailored to provoke responses and interactions from your audience.",
      "Timely Responses: Automated responses and interactions help keep your audience engaged and your social media presence active.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Ease of Use",
    description: [
      "User-Friendly Interface: Manage your social media effortlessly with an intuitive interface that simplifies content management and analytics.",
      "Minimal Effort Required: Set up your preferences and let the AI handle the rest, reducing the time and effort required to manage social media.",
    ],
  },
];
