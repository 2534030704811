import React, { useState } from 'react';
import { Button, Modal, Result } from 'antd';
import axios from 'axios';
import swal from "sweetalert";

const RequestPaymentModal = ({requestPaymentOpen, setRequestPaymentOpen, Earningtotal, email, payId}) => {
    const [amount, setAmount] = useState("0.00") 

    const handleSubmit = async (e) =>{
        e.preventDefault()

        if(Earningtotal < amount){
            return
        }

        const data = {
            email, 
            amount:amount,
            paymentId:payId,
        }

        console.log(data, 'DATA TO POST')

        await axios
        .post(
          "https://backend.whalesai.com/api/v1/earnings-payment",
          data,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p></p> <p class="text-white">Payment request successful.</span></p> `;
            swal({
              // title: "Are you sure?",
              content: wrapper,
              icon: "success",
              button: "Ok",
              className: "modal_class_success",
            })
          }
        });
    }

    return (
         <Modal closable={false} className='request-payment-modal'  footer={null} centered open={requestPaymentOpen} onOk={()=> setRequestPaymentOpen(false)} onCancel={()=> setRequestPaymentOpen(false)}>
            <form action="">
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h5 className='mb-0'>Withdraw</h5>
                <span onClick={()=> {
                  setAmount("0.00")
                  setRequestPaymentOpen(false)
                  }} className='close-icon-button'><i class="fa-solid fa-xmark"></i></span>
            </div>
            <p className='mb-1'>Enter Withdraw amount</p>
            <div className='position-relative'>
                <input required={true} className='w-100 py-1' value={amount} onChange={(e)=> setAmount(e.target.value)} type="number"  />
                <button onClick={()=> setAmount(Earningtotal)} type='button' className='max-button'>MAX</button>
            </div>
            <div className="modal-footer d-flex gap-2 justify-content-end mt-4">
                    <Button onClick={()=> {
                      setAmount("0.00")
                      setRequestPaymentOpen(false)
                      }} type='primary' danger>CANCEL</Button>
                    <Button onClick={(e)=> handleSubmit(e)} type='primary' style={{backgroundColor:"green"}}>SUBMIT</Button>
            </div>
            </form>
         </Modal>
    );
};

export default RequestPaymentModal;