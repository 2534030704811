import React from "react";
import ReactMarkdown from "react-markdown";

const ADS = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {
  return (
    <>
      <p class="preset-title">Advertisement</p>
      {inputText ? (
          <>
            <p class="text-muted">{inputText}</p>

            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && (
                      <>
                        <p style={{ color: "black" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          </>
      ) : (
        <>
          <p className="text-muted" style={{ fontSize:"14px" }}>
            Learning Room is a virtual environment to help students from
            kindergarten to high school excel in school.
          </p>

          <p style={{ color: "black",fontSize:"14px" }}>
            <ReactMarkdown>{presetSelected.label}</ReactMarkdown>
          </p>
        </>
      )}
    </>
  );
};

export default ADS;
