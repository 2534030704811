import React from 'react';
import { IconButton } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import swal from "sweetalert";

const DesignButton = ({ isDark,isRandomBackground,isRandomFont,isRandomLine,isRandomDesign, handleCheckboxChange, handleCheckboxChange1, handleCheckboxChange2, handleCheckboxChange3, handleCheckboxChange4, setModalShow, setModalShow1, setModalShow2, setModalShow3, setModalShow4 }) => {
    return (
        <div className="my-3">
            <div className="d-flex align-items-center justify-content-center mb-2 gap-2">
                <input
                    type="checkbox"
                    checked={isDark}
                    onChange={handleCheckboxChange}
                />
                <p className="ms-2 mb-0 text-white" style={{ fontSize: "14px" }}>Classic Black and White</p>
                <IconButton
                    style={{ color: "#ffa21f" }}
                    onClick={() => setModalShow(true)}
                    title="Show Image sample"
                    className="ms-1 py-0"
                >
                    <InfoIcon />
                </IconButton>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-2 gap-2">
                <input
                      type="checkbox"
                      checked={isRandomDesign}
                      onChange={handleCheckboxChange1}
                    // type="checkbox"
                    // checked={isRandomLine}
                    // onChange={handleCheckboxChange4}
                />
                <p className="ms-2 my-0 text-white" style={{ fontSize: "14px" }}>Random Line</p>
                <IconButton
                    style={{ color: "#ffa21f" }}
                    onClick={() => setModalShow1(true)}
                    title="Show Image sample"
                    className="ms-1 py-0"
                >
                    <InfoIcon />
                </IconButton>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-2 gap-2">
                <input
                    type="checkbox"
                    checked={isRandomFont}
                    onChange={handleCheckboxChange3}
                />
                <p className="ms-2 my-0 text-white" style={{ fontSize: "14px" }}>Random Font</p>
                <IconButton
                    style={{ color: "#ffa21f" }}
                    onClick={() => setModalShow3(true)}
                    title="Show Image sample"
                    className="ms-1 py-0"
                >
                    <InfoIcon />
                </IconButton>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center mb-2 gap-2">
                <input
                    type="checkbox"
                    checked={isRandomDesign}
                    onChange={handleCheckboxChange1}
                />
                <p className="ms-2 my-0 text-white" style={{ fontSize: "14px" }}>Random Design</p>
                <IconButton
                    style={{ color: "#ffa21f" }}
                    onClick={() => setModalShow1(true)}
                    title="Show Image sample"
                    className="ms-1 py-0"
                >
                    <InfoIcon />
                </IconButton>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-2 gap-2">
                <input
                    type="checkbox"
                    checked={isRandomBackground}
                    onChange={handleCheckboxChange2}
                />
                <p className="ms-2 my-0 text-white" style={{ fontSize: "14px" }}>Random Background</p>
                <IconButton
                    style={{ color: "#ffa21f" }}
                    onClick={() => setModalShow2(true)}
                    title="Show Image sample"
                    className="ms-1 py-0"
                >
                    <InfoIcon />
                </IconButton>
            </div> */}




        </div>
    );
};

export default DesignButton;