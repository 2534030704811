import axios from "axios";
const apiKey = "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";
// const apiKey = "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";
export const handleAiCompletion = async (prompt, preset) => {
  console.log("prompt", prompt, "preset", preset);
  try {
    // if(preset == "QA" &&)
    const response = await axios.post(
      "https://api.openai.com/v1/completions",
      {
        // model: preset === "EC" ? "code-davinci-002" : "gpt-3.5-turbo-instruct",
        model: preset === "EC" ? "gpt-3.5-turbo-instruct" : "gpt-3.5-turbo-instruct",
        prompt: prompt,
        temperature: 0,
        max_tokens: 200,
        // 2048
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    console.log('api response =====================================>>>>>>>>' , response);

    let answer;
    if (
      preset === "ASK" ||
      preset === "NLTP" ||
      preset === "MTE" ||
      preset === "JTP" ||
      preset === "MHSC" ||
      preset === "videoai"
    ) {
      answer = response.data.choices[0].text;
    } else if (preset == "CHAT") {
      answer = response.data.choices[0].text.trim();
      
    } else if (preset == "EC") {
      answer = response.data.choices[0].text
        .split("*/")[0]
        .split("\n")
        .slice(0, 7)
        .join("\n");
      // answer = response.data.choices[0].text.split('\n\n', 2)[1];
    } else if (preset == "QA"){
      answer = response.data.choices[0].text.split("\n")[1].trim();
    } 
    else {
      answer = response.data.choices[0].text.split("\n\n", 2)[1];
    }

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const handleAiChatCompletions = async (prompt, preset) => {
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4",
        messages: [{ role: "assistant", content: prompt }],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );

    const answer = response.data.choices[0].message.content;

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// export const handleAiChatCompletionsAssistant = async (question,) => {
//   try {
//     console.log("Consultant AI texttttttttttttttttt question ============>>>> : ", question)
//     const assistant = await axios.post(
//       'https://backend.dsl.sg/api/v1/openai/assistant/emphathetic-replier',
//       { question },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
//         },
//       }
//     );

//     const responseArray = assistant.data.response;
//     const assistantResponse = responseArray[0].replace("Assistant: ", "");
//     console.log('Response from API===============================================:', assistantResponse);

//     return assistantResponse;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// Function to handle AI chat completions
export const handleAiChatCompletionsAssistant = async (question, lastQues) => {
  try {
    if (lastQues?.toLowerCase().includes("who are you")) {
      const randomResponse = getRandomResponse();
      return randomResponse;
    }
    // Call the API for other questions
    const assistant = await axios.post(
      "https://backend.dsl.sg/api/v1/openai/assistant/emphathetic-replier",
      { question },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
        },
      }
    );

    // Extract response from API
    const responseArray = assistant.data.response;
    const assistantResponse = responseArray[0].replace("Assistant: ", "");
    console.log("Response from API:", assistantResponse);

    return assistantResponse;
  } catch (error) {
    console.error("Error:", error);
  }
};

const getRandomResponse = () => {
  const responses = [
    "I hail from DS Legends Pte. Ltd. as a Consultant AI, crafted to be your virtual buddy, providing emphatic responses to your inquiries.",
    "Designed by DS Legends Pte. Ltd., I stand as your Consultant AI, ready to engage with you and provide emphatic answers to your queries.",
    "Developed by DS Legends Pte. Ltd., I am your faithful Consultant AI, here to offer emphatic responses to all your questions.",
    "From the labs of DS Legends Pte. Ltd. emerges me, your Consultant AI, primed to engage with you and provide emphatic answers.",
    "I am a creation of DS Legends Pte. Ltd., fashioned to be your Consultant AI, offering emphatic answers to your inquiries.",
    "DS Legends Pte. Ltd. brings you me, your Consultant AI, dedicated to answering your questions emphatically.",
    "Crafted by DS Legends Pte. Ltd., I am your trusty Consultant AI, equipped to respond emphatically to all your questions.",
    "From the innovation hub of DS Legends Pte. Ltd. comes your Consultant AI, here to support you with emphatic answers.",
    "I hail from DS Legends Pte. Ltd., standing as your Consultant AI, prepared to provide emphatic responses to your questions.",
    "Developed by DS Legends Pte. Ltd., I am your virtual Consultant AI, ready to engage and answer your questions emphatically.",
  ];

  const randomIndex = Math.floor(Math.random() * responses.length);
  return responses[randomIndex];
};

export const handleAiChatCompletionsVideo = async (prompt, preset) => {
  try {
    // Check if the question includes "who are you"
    if (preset?.toLowerCase().includes("who are you")) {
      const randomResponse = getRandomResponse();
      return randomResponse;
    }
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [{ role: "assistant", content: prompt }],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );

    const answer = response.data.choices[0].message.content;

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
