import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SampleVideoModal({ modalShow, setModalShow }) {
  const handleClose = () => setModalShow(false);
  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalShow}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2 text-white">
            <Typography variant="h6">Sample</Typography>
          </div>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        </div>
        </DialogTitle>
        
        <DialogContent>
          <video
            controls
            style={{ width: "100%" }}
            src="/sample_video.mp4"
            autoPlay
            poster="https://i.ibb.co/DYg2YgK/sample.jpg"
            className=""
            type="video/mp4"
          />
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
