import React, { useContext, useEffect, useRef, useState } from "react";
import "./Banner.css";

import banner1 from "../../../Assets/Images/method01.png";
import banner2 from "../../../Assets/Images/method2.png";
import banner11 from "../../../Assets/Images/M1.jpg";
import banner21 from "../../../Assets/Images/M2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Controller } from "swiper";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../../Contexts/UserAuthContext";
import { sentences } from "./data";

const Banner = () => {
  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  const [currentSentence, setCurrentSentence] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [controlledSwiper1, setControlledSwiper1] = useState(null);
  const [controlledSwiper2, setControlledSwiper2] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const preloadImages = (imageUrls) => {
      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };
    preloadImages([banner1, banner2, banner11, banner21]);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 2);
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  useEffect(() => {
    const updateSentence = () => {
      const randomIndex = Math.floor(Math.random() * sentences.length);
      setCurrentSentence(sentences[randomIndex]);
    };

    updateSentence(); // set initial sentence
    const intervalId = setInterval(updateSentence, 15000); // update every  seconds

    return () => clearInterval(intervalId); // clean up on unmount
  }, []);

  return (
    <div className="banner-container">
      <div className="container banner-main row mx-auto px-4">
        {/* <div className="col-12 col-md-6 col-lg-6 position-relative d-none d-md-flex d-lg-flex">
          <img
            onClick={() => navigate("/do-it-yourself")}
            className="img-fluid"
            src={method1[currentImageIndex]}
            alt="A complete social media marketing manager"
          />
          <p className="free-2000">
            {AIuser
              ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
              : "Sign up now and get 2000 FREE CREDITS"}
          </p>
        </div> */}
        {/* Large device method-1 banner  */}
        <div className="col-12 col-md-6 col-lg-6 position-relative d-none d-md-flex d-lg-flex">
          <Swiper
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Controller]}
            className="mySwiper"
            onSwiper={setControlledSwiper1}
          >
            <SwiperSlide>
              <img
                onClick={() => navigate("/do-it-yourself")}
                className="img-fluid"
                src={banner1}
                alt="A complete social media marketing manager"
              />
              <p className="free-20001">
                  {AIuser
                    ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
                    : "Sign up now and get 2000 FREE CREDITS"}
                </p>
            </SwiperSlide>
            <SwiperSlide>
              <img
                onClick={() => navigate("/do-it-yourself")}
                className="img-fluid"
                src={banner11}
                alt="A complete social media marketing manager"
              />
              <p className="free-20001">
                  {AIuser
                    ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
                    : "Sign up now and get 2000 FREE CREDITS"}
                </p>
            </SwiperSlide>
          </Swiper>
          {/* <p className="free-2000">
            {AIuser
              ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
              : "Sign up now and get 2000 FREE CREDITS"}
          </p> */}
        </div>

        {/* Large device method-2 banner  */}
        <div className="col-6 d-none d-md-flex d-lg-flex position-relative">
          <Swiper
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
            onSwiper={setControlledSwiper2}
          >
            <SwiperSlide>
              <img
                onClick={() => navigate("/let-us-do-it-for-you")}
                className="img-fluid"
                src={banner2}
                alt="image"
                style={{ cursor: "pointer" }}
              />
              <p
            className="free-2000"
            dangerouslySetInnerHTML={{ __html: currentSentence }}
          />
            </SwiperSlide>
            <SwiperSlide>
              <img
                onClick={() => navigate("/let-us-do-it-for-you")}
                className="img-fluid"
                src={banner21}
                alt="image"
                style={{ cursor: "pointer" }}
              />
              <p
            className="free-2000"
            dangerouslySetInnerHTML={{ __html: currentSentence }}
          />
            </SwiperSlide>
          </Swiper>
          {/* <p
            className="free-2000"
            dangerouslySetInnerHTML={{ __html: currentSentence }}
          /> */}
        </div>

        {/* <div className="col-6 d-none d-md-flex d-lg-flex position-relative">
          <img
            onClick={() => navigate("/let-us-do-it-for-you")}
            className="img-fluid"
            src={method2[currentImageIndex]}
            alt="image"
            style={{ cursor: "pointer" }}
          />
          <p
            className="free-2000"
            dangerouslySetInnerHTML={{ __html: currentSentence }}
          />
        </div> */}
        {/* <p className="free-2000">
            {AIuser
              ? `Now you have ${Number(AIuser?.credits).toLocaleString()} CREDITS`
              : 'Sign up now and get 2000 FREE CREDITS'}
          </p> */}

        {/* banner in slider for small device */}

        <div className="col-12 d-md-none d-lg-none">
          <Swiper
            slidesPerView={1}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="position-relative">
                {/* <video width={"100%"} autoPlay>
  <source src="/videos.mp4" type="video/mp4"/>
  <source src="mov_bbb.ogg" type="video/ogg"/>
</video> */}
                {/* <img className="img-fluid" src={banner1} alt="image" /> */}
                <img
                  onClick={() => navigate("/do-it-yourself")}
                  className="img-fluid"
                  src={banner1}
                  alt="A complete social media marketing manager"
                />
                <p className="free-20001">
                  {AIuser
                    ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
                    : "Sign up now and get 2000 FREE CREDITS"}
                </p>
                {/* <button
                  onClick={() => navigate(AIuser ? "/dashboard" : "/sign-in")}
                  className="trial-button"
                >
                  {AIuser ? "Start Generating" : "Start Free Trial"}
                </button> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="">
                <img
                  onClick={() => navigate("/let-us-do-it-for-you")}
                  className="img-fluid"
                  src={banner2}
                  alt="image"
                  style={{ cursor: "pointer" }}
                />
                <p
                  className="free-20001"
                  dangerouslySetInnerHTML={{ __html: currentSentence }}
                />
                {/* <p className="free-20001">
                  {AIuser
                    ? `Now you have ${Number(AIuser?.credits).toLocaleString()} CREDITS`
                    : 'Sign up now and get 2000 FREE CREDITS'}
                </p> */}
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="position-relative">
                <img
                  onClick={() => navigate("/do-it-yourself")}
                  className="img-fluid"
                  src={banner11}
                  alt="A complete social media marketing manager"
                />
                <p className="free-20001">
                  {AIuser
                    ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
                    : "Sign up now and get 2000 FREE CREDITS"}
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="">
                <img
                  onClick={() => navigate("/let-us-do-it-for-you")}
                  className="img-fluid"
                  src={banner21}
                  alt="image"
                  style={{ cursor: "pointer" }}
                />
                <p
                  className="free-20001"
                  dangerouslySetInnerHTML={{ __html: currentSentence }}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Banner;
