import React from "react";
import ReactMarkdown from "react-markdown";

const ASK = ({ inputText, isGenerating, text, formattedText }) => {
  console.log(inputText, isGenerating, text, formattedText);
  return (
    <>
      <p class="preset-title">Ask Anything</p>

      {inputText ? (
        <>
          <p class="text-muted mb-0">
            {/* {inputText} */}
            <ReactMarkdown>{inputText}</ReactMarkdown>
          </p>
          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
              {/* {showCursor && (
                  <span className="textai-cursor-effect">
                    |
                  </span>
                )} */}
            </p>
          ) : (
            <>
              <p
                style={{
                  color: "black",
                  marginTop: "-35px",
                }}
                class=""
              >
                {formattedText}
              </p>
              {/* {showCursor && (
                    <span className="textai-cursor-effect">
                      |
                    </span>
                  )} */}
            </>
          )}
        </>
      ) : (
        <>
          <p className="text-muted" style={{fontSize:"14px" }}>What is artificial intelligence?</p>
          <p
          style={{ color: "black",fontSize:"14px" }}
          >
            Artificial intelligence (AI) is a branch of computer science that
            aims to create intelligent machines that can think and act like
            humans. AI systems are designed to learn from their environment and
            experiences, and to use that knowledge to solve problems and make
            decisions. AI can be used to automate tasks, improve
            decision-making, and create new products and services.
          </p>
        </>
      )}
    </>
  );
};

export default ASK;
