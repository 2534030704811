import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

const JTP = ({ inputText, isGenerating, text, presetSelected }) => {
  return (
    <>
      <p class="preset-title">JavaScript To Python</p>

      {inputText ? (
        <>
          <p class="text-muted syntax-high">
            <SyntaxHighlighter
              language="javascript"
              // style={darcula}
              autodetect={true}
              dark={true}
              // style={{ backgroundColor: "#011c34" }}
            >
              {inputText}
            </SyntaxHighlighter>
          </p>

          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
              {text && (
                <p
                  style={{
                    color: "black",
                  }}
                  class="text- mb-0 text-"
                >
                  <SyntaxHighlighter
                    language="javascript"
                    // style={darcula}
                    autodetect={true}
                    dark={true}
                  >
                    {text}
                  </SyntaxHighlighter>
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <SyntaxHighlighter
            language="javascript"
            // style={darcula}
            autodetect={true}
            dark={true}
          >
            {presetSelected?.label}
          </SyntaxHighlighter>
        </>
      )}
    </>
  );
};

export default JTP;
