import React, { useContext, useEffect, useState } from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';
import './Transaction.css';
import axios from 'axios';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import moment from 'moment';
import { FaEye } from 'react-icons/fa';
import { Pagination } from '@mui/material';
import ViewTransactionsModal from './ViewTransactionModal';
import { useNavigate } from 'react-router-dom';

const Transaction = () => {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const { AIuser } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const [isChecking, setIschecking] = useState(false);
  console.log(AIuser?._id);

  const itemsPerPage = 12; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = transactionDetails?.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 3);
    setCurrentPage(page);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getTransaction = async () => {
      setIschecking(true);
      const response = await axios.get(
        `https://backend.whalesai.com/api/v1/credits-transaction/get/${AIuser?._id}`
      );
      console.log(response?.data?.data, 'all the data');
      setTransactionDetails(response?.data?.data?.reverse());

      setIschecking(false);
    };
    getTransaction();
  }, [AIuser?._id]);

  const handleRedirectToView = (details) => {
    if (details?.aiType === 'Text Ai') {
      navigate('/text-ai', {
        state: {
          aiType: details?.aiType,
          textAiType: details?.textAiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          response: details?.response,
          requestQues: details?.requestQues,
        },
      });
    } else if (details?.aiType === 'Consultant Ai') {
      navigate('/consultant-ai', {
        state: {
          aiType: details?.aiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          usingChat: true,
          messages: details?.companionAi,
          companionAiVoice: details?.companionAiVoice,
          companionAiGender: details?.companionAiGender,
        },
      });
    } else if (details?.aiType === 'Image Ai') {
      navigate('/image-ai', {
        state: {
          aiType: details?.aiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          response: details?.response,
          requestQues: details?.requestQues,
          imageAiSize: details?.imageAiSize,
          textwithHashTag: details?.textwithHashTag,

        },
      });
    } else if (details?.aiType === 'Video Ai') {
      navigate('/generated-video', {
        state: {
          aiType: details?.aiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          videoAi: details?.videoAi[0],
          videoAiPages1: details?.videoAiPages1,
          isVideoExpire: details?.isVideoExpire,
          videoAiPages16: details?.videoAiPages16,
          videoAiPages9: details?.videoAiPages9,
          videoAiDuration: details?.videoAiDuration,
        },
      });
    } else if (details?.aiType === 'Post Ai') {
      navigate('/post-ai', {
        state: {
          aiType: details?.aiType,
          postDataType: details?.postDataType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          userId: details?.userId,
          preset: details?.preset,
          credit: details?.credit,
          setShow: true,
          ...(details?.postDataType === 'postAi-01' && { postData1: details?.postData1 }),
          ...(details?.postDataType === 'postAi-02' && { postData2: details?.postData2 }),        
       },
      });
    }
  };

  return (
    <div>
      <PageHeading title={'TRANSACTIONS'} />
      <div className="container account-container pb-5">
        {isChecking ? (
          <div className="text-center my-5">
            <h4 className="text-light">Checking...</h4>
          </div>
        ) : (
          <>
            <div className="row gx-2 gy-2">
              {transactionDetails?.length === 0 ? (
                <div className="text-center my-5">
                  <h4 className="text-light">No Transactions Found</h4>
                </div>
              ) : (
                <>
                  {paginatedData?.map((details, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                      <div className="card ">
                        <ul className="list-group list-group-flush text-uppercase">
                          <li className="list-group-item">
                            <span style={{ fontWeight: '500' }}>Date:</span>{' '}
                            {moment(details?.createdAt).format(
                              'DD-MM-YYYY HH:mm:ss'
                            )}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: '500' }}>
                              Transaction Id:
                            </span>{' '}
                            {details?.transactionId}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: '500' }}>
                              Type of request:
                            </span>{' '}
                            {details?.aiType === 'Video Ai'
                              ? details?.videoTitle
                              : details?.aiType}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: '500' }}>
                              Credits used:
                            </span>{' '}
                            {details?.totalCreditsUsed}
                          </li>
                          <li className="list-group-item text-center">
                            <button
                              className="view-button w-100"
                              // onClick={() => handleClickOpen(details)}
                              onClick={() => handleRedirectToView(details)}
                            >
                              VIEW{' '}
                              <FaEye
                                className=""
                                style={{ fontSize: 16, marginTop: '-2px' }}
                              />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="d-flex justify-content-center text-light mt-3">
              <Pagination
                shape="rounded"
                count={Math.ceil(transactionDetails?.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Transaction;
