import React, { useEffect } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./ContentGenerator.css";
import Banner from "../Home/Banner/Banner";
import MetaData from "../../Components/MetaData/MetaData";
import { useNavigate } from "react-router-dom";

const ContentGenerator = () => {
  const data = [
    {
      advantage: "Increased Engagement",
      description:
        "Regular posts keep your audience engaged, encouraging likes, comments, shares, and interactions that enhance visibility and connection.",
    },
    {
      advantage: "Consistency and Brand Recognition",
      description:
        "Frequent posting helps in maintaining a consistent brand presence, making it easier for followers to recognize and remember your brand.",
    },
    {
      advantage: "Algorithm Favorability",
      description:
        "Social media algorithms often favor accounts that post regularly, leading to better reach and visibility in followers' feeds.",
    },
    {
      advantage: "Building Trust and Authority",
      description:
        "Consistently sharing valuable content establishes your brand as an authority in your field, fostering trust among your audience.",
    },
    {
      advantage: "Increased Traffic and Conversions",
      description:
        "Regular posts can drive more traffic to your website or online store, potentially leading to higher conversion rates.",
    },
    {
      advantage: "Staying Top of Mind",
      description:
        "Daily posts ensure your brand stays at the forefront of your audience's mind, which can be crucial for maintaining customer loyalty and awareness.",
    },
    {
      advantage: "Real-Time Marketing",
      description:
        "Frequent posting allows you to respond to current events, trends, or changes in your industry promptly, showcasing your brand's relevance and agility.",
    },
    {
      advantage: "Feedback and Insights",
      description:
        "Daily interactions provide continuous feedback from your audience, helping you understand their preferences and adjust your strategies accordingly.",
    },
    {
      advantage: "Improved SEO",
      description:
        "Social media activity can indirectly impact your search engine rankings by driving traffic to your site and generating backlinks.",
    },
    {
      advantage: "Content Experimentation",
      description:
        "Posting daily provides opportunities to experiment with different types of content and formats to see what resonates best with your audience.",
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0,0);
  } ,[])

  return (
    <div>
               <MetaData
        pageTitle={
          "Best Generative AI Tool for Beginners | Easy Generative AI"
        }
        pageDescription={
          "Find the best generative AI tools for beginners to create text, images, audio, and more. Enjoy user-friendly platforms designed for easy learning and creativity."
        }
        canonical="https://whalesai.com/ai-social-media-content-generator"
      ></MetaData>
      <div className="">
        <PageHeading title="Ai social media content generator" />

        <section className=" container page-content pt-0 px-4 text-white">
          <p>You can generate different contents for social media platforms using our apis. </p>
          <div className="mt-3">
          <button
              type="button"
              className="btn btn-warning px-2"
              style={{width: '300px'}}
              onClick={() => {
                window.open(
                  "https://backend.whalesai.com/public/text-article-generator-1.1.pdf",
                  "_blank"
                );
              }}
            >
              Click here for Text Article Generator
            </button>
        </div>


        <div className="mt-3">
          <button
              type="button"
              className="btn btn-info px-2"
              style={{width: '300px'}}
              onClick={() => {
                navigate('/test-text-article-generator')
              }}
            >
              Test how it works
            </button>
        </div>
        </section>
      </div>

      <Banner />
    </div>
  );
};

export default ContentGenerator;
