import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { ethers } from 'ethers';

import {
  contractAddressDssToken,
  contractAddressDssPackage,
  abiDssToken,
  abiDssPackage,
} from "../utils/constant";

export const UserAuthContext = createContext();

export default function UserAuthProvider({ children }) {
  const [AIuser, setAIuser] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenLocal, setTokenLocal] = useState(null);
  const [isGet, setIsGet] = useState(false);
  const [isBack, setisBack] = useState(false);
  const [userRefetch, setUserRefetch] = useState(false);
  const [creditsData, setCreditsData] = useState();
  const [initialTimer, setInitialTimer] = useState(0);
  const [transactionDetails, setTransactionDetails] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("aiUser")) {
      axios
        .get(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/current-user",
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setAIuser(res.data.result);
            setIsGet(true);
          }
        })
        .finally(() => { });
      setUserRefetch(false);
    }
  }, [userRefetch, localStorage.getItem("aiUser"),tokenLocal]);

  useEffect(() => {
    const getTransaction = async () => {
      const response = await axios.get(
        `https://backend.whalesai.com/api/v1/payment/${AIuser?._id}`
      );
      setTransactionDetails(response?.data?.result?.reverse());
    };
    getTransaction();
  }, [AIuser?._id]);

  const logout = () => {
    setAIuser(null);
    localStorage.removeItem("aiUser");

    swal({
      // title: "Success",
      text: `Thank you for visiting us! 🚀  \n\n Stay ahead of the curve with our AI-driven insights and tips. Follow us for the latest trends and updates in social media marketing. \n\n Need help? Have questions? We're just a click away! \n\n Until next time, happy marketing! 🌟`,
      icon: "success",
      button: "OK!",
      className: "modal_class_success",
    });
  };


  // ------------------------Blockchain Work------------------------

  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentAccountDssToken, setCurrentAccountDssToken] = useState(null);
  const [walletModal, setWalletModal] = useState(false);

  const openWalletModal = () => setWalletModal(true);
  const closeWalletModal = () => setWalletModal(false);

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);


  const { ethereum } = window;



  const connectToMetamask = async () => {
    if (typeof window.ethereum === "undefined") {
      // ask the user to install the extension
      return swal({
        title: "Attention",
        text: "Please open this website with wallet browsers",
        icon: "warning",
        button: "OK",
        dangerMode: true,
        className: "modal_class",
      });
    }

    let provider = null;
    if (typeof window.ethereum !== "undefined") {
      provider = window.ethereum;
      // edge case if MM and CBW are both installed
      // if (window.ethereum.providers?.length) {
      //   for (const p of window.ethereum.providers) {
      //     if (p.isMetaMask) {
      //       provider = p;
      //       break;
      //     }
      //   }
      // }

      try {
        const chainId = await provider.request({
          method: "eth_chainId",
        });
        console.log("This is Chain ID: ", chainId);
        if (chainId === "0x38") { // for mainnet
          // if (chainId === "0x61") { //for testnet
          const accounts = await provider.request({
            method: "eth_requestAccounts",
          });
          console.log(accounts[0]);
          setCurrentAccount(accounts[0]);


          await axios
            .post(`https://backend.whalesai.com/api/v1/wallet-user/`, {
              walletAddress: accounts[0],
            })
            .then((res) => {
              if (res.data.user) {
                setUser(res.data.user);
                setLoading(false);
                closeWalletModal();
                localStorage.setItem("whalesaiToken", res.data.token);
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have succesfully logged in with <br/>Binance (Mainnet).</p>`;
                return swal({
                  content: wrapper,
                  icon: "success",
                  button: "OK",
                  className: "modal_class_success",
                }).then((willDelete) => {
                  if (willDelete) {
                    console.log("OK")
                  }
                });
              }
            });


        } else {
          console.log("Please Switch to Binance Chain");
          swal({
            title: "Attention",
            // text: "Please change to Binance Chain (Testnet) before connecting.", //for testnet
            text: "Please change to Binance Chain before connecting.", //for mainnet
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        }
      } catch (error) {
        console.log("User Rejected");
        // throw new Error("User Rejected");
      }
    } else {
      swal({
        title: "Attention",
        text: "Please change to Binance Chain (Testnet) before connecting.",
        icon: "warning",
        button: "OK",
        dangerMode: true,
        className: "modal_class",
      });
      throw new Error("No MetaMask Wallet found");
    }

    console.log("MetaMask provider", provider);
  };


  useEffect(() => {
    if (currentAccount && localStorage.getItem("whalesaiToken")) {
      setLoading(true);
      axios
        .get(`https://backend.whalesai.com/api/v1/wallet-user/`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("whalesaiToken")}`,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      setUserRefetch(false);
    }
  }, [currentAccount, userRefetch, localStorage.getItem("whalesaiToken")]);


  const getTokenContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner();
    const Token = new ethers.Contract(
      contractAddressDssToken,
      abiDssToken,
      signer
    );
    return Token;
  };

  const getPackageContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner();
    const Token = new ethers.Contract(
      contractAddressDssPackage,
      abiDssPackage,
      signer
    );
    return Token;
  };


  const getWalletAccountAddress = async () => {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    setCurrentAccount(address);
    return address;
  };

  const getDssTokenBalance = async () => {
    try {
      let accountAddress = await getWalletAccountAddress();
      const res = await getTokenContract().balanceOf(accountAddress);
      let data = ethers.utils.formatEther(res.toString());
      setCurrentAccountDssToken(data)
      return data;
    } catch (err) {
      console.log("Balance Error", err);
    }
  };


  const getApprove = async (value) => {
    let state = { status: '', message: '', return: '' };
    try {

      let payment_test;
      const amount = ethers.utils.parseEther(value);
      const provider = new ethers.providers.Web3Provider(ethereum);
      const tokenContract = getTokenContract();
      const estimatedGasLimit = await tokenContract.estimateGas.approve(
        contractAddressDssPackage,
        amount,
        {
          from: currentAccount,
          maxFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
          maxPriorityFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
        }
      );
      console.log("🚀 ~ file: UserAuthContext.js:272 ~ getApprove ~ estimatedGasLimit:", estimatedGasLimit.toString())
      const approve = await tokenContract.approve(
        contractAddressDssPackage,
        amount,
        {
          from: currentAccount,
          gasLimit: estimatedGasLimit,
          gasPrice: ethers.utils.parseUnits('50', 'gwei'),
          // maxFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
          // maxPriorityFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
        }
      );
      approve.wait();
      payment_test = await provider.getTransaction(approve.hash);
      while (payment_test.blockNumber === null) {
        console.log("Approve In Progress...");
        payment_test = await provider.getTransaction(approve.hash);
      }
      const receipt = await provider.getTransactionReceipt(approve.hash);
      if (receipt.status === 1) {
        console.log("Transaction successful");
        state.status = 'Success';
        state.message = 'Transaction successful';
        state.return = true;
        return state;
      } else if (receipt.status === 0) {
        console.log("Transaction unsuccessful");
        state.status = 'warning';
        state.message = 'Transaction unsuccessful';
        state.return = false;
        return state;
      }
    } catch (err) {
      if (err.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = 'warning';
        state.message = 'User rejected the transaction.';
        state.return = false;
        return state;
      } else {
        console.log("approve failed", err);
        state.status = 'warning';
        state.message = 'Transaction failed.';
        state.return = false;
        return state;
      }
    }
  };


  const buyPackageFromContract = async (packageNo) => {
    let state = { status: '', message: '', return: '', data: '' };
    try {

      let payment_test;
      const provider = new ethers.providers.Web3Provider(ethereum);
      const packageContract = getPackageContract();

      const estimatedGasLimit = await packageContract.estimateGas.buyPackage(
        packageNo,
        {
          from: currentAccount,
          maxFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
          maxPriorityFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
        }
      );

      const buyPack = await packageContract.buyPackage(packageNo, {
        from: currentAccount,
        gasLimit: estimatedGasLimit,
        gasPrice: ethers.utils.parseUnits('50', 'gwei'),
        // maxFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
        // maxPriorityFeePerGas: ethers.utils.parseUnits('50', 'gwei'),
      });

      await buyPack.wait();
      payment_test = await provider.getTransaction(buyPack.hash);
      while (payment_test.blockNumber === null) {
        console.log("package In Progress...");
        payment_test = await provider.getTransaction(buyPack.hash);
      }
      const receipt = await provider.getTransactionReceipt(buyPack.hash);
      if (receipt.status === 1) {
        let buyPack_hash = "https://bscscan.com/tx/" + buyPack.hash;
        console.log("Transaction successful");
        state.status = 'Success';
        state.message = 'Transaction successful';
        state.data = buyPack_hash
        state.return = true;
        return state;
      } else if (receipt.status === 0) {
        console.log("Transaction unsuccessful");
        state.status = 'warning';
        state.message = 'Transaction unsuccessful';
        state.return = false;
        return state;
      }
    } catch (err) {
      console.log("package failed", err);
      if (err.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = 'warning';
        state.message = 'User rejected the transaction.';
        state.return = false;
        return state;
      } else {
        console.log("package failed", err);
        state.status = 'warning';
        state.message = 'Transaction failed.';
        state.return = false;
        return state;
      }
    }
  };

  ethereum &&
    ethereum.on("accountsChanged", async (account) => {
      if (account.length > 0) {
        getDssTokenBalance()
        setCurrentAccount(account[0]);
        const start = account[0]?.substring(0, 6);
        const end = account[0]?.substring(account[0].length - 4);
        return swal({
          title: "success",
          text: `Account changed to ${start}...${end} successfully`,
          icon: "success",
          button: "OK",
          dangerMode: true,
          className: "modal_class_success",
        });
      } else {
        setCurrentAccount(null);
        return swal({
          title: "warning",
          text: `No account detected`,
          icon: "warning",
          button: "OK",
          dangerMode: true,
          className: "modal_class_success",
        });
      }
    });
  useEffect(() => {
    if (requestLoading) {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Please wait, don't exit screen...<b></p> `;
      swal({
        content: wrapper,
        button: false,
        className: "modal_class_success",
      });
    }
  }, [requestLoading]);




  return (
    <UserAuthContext.Provider
      value={{
        AIuser,
        user,
        setAIuser,
        AiLogOut: logout,
        token,
        isGet,
        setIsGet,
        isBack,
        setisBack,
        setToken,
        setUserRefetch,
        userRefetch,
        creditsData,
        setCreditsData,
        initialTimer,
        setInitialTimer,
        setTransactionDetails,
        transactionDetails,
        // ---------------------------
        walletModal,
        currentAccountDssToken,
        currentAccount,
        getDssTokenBalance,
        openWalletModal,
        closeWalletModal,
        connectToMetamask,
        getApprove,
        setRequestLoading,
        requestLoading,
        buyPackageFromContract,
        setTokenLocal,
        tokenLocal
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}
