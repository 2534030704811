// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Dialog from "@mui/material/Dialog";
// import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

// export default function SampleImageModal({ modalImage, setModalImage }) {
//   const handleClose = () => setModalImage(false);

//   return (
//     <BootstrapDialog
//       onClose={handleClose}
//       scroll="body"
//       aria-labelledby="customized-dialog-title"
//       open={modalImage}
//     >
//       <Box
//         sx={{
//           backgroundColor: "#3080ab",
//           color: "#fff",
//           width: {
//             lg: "500px",
//           },
//         }}
//       >
//         <DialogTitle
//           sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
//           id="customized-dialog-title"
//         >
//           <div className="d-flex align-items-center justify-content-between">
//             <div className="d-flex align-items-center gap-2 text-white">
//               <Typography variant="h6">Sample</Typography>
//             </div>
//             <IconButton
//               aria-label="close"
//               onClick={handleClose}
//               sx={{
//                 position: "absolute",
//                 right: 8,
//                 color: "white",
//               }}
//             >
//               <CloseIcon />
//             </IconButton>
//           </div>
//         </DialogTitle>

//         <DialogContent>
//           {/* Image Section */}
//           <img
//             src="https://backend.whalesai.com/public/imageai/15082024-267001.png"
//             alt="Sample"
//             style={{ width: "100%", borderRadius: "8px" }}
//           />

//           {/* Text and Hashtag Section */}
//           <Box
//             sx={{
//               marginTop: 2,
//               padding: 2,
//               backgroundColor: "#fff",
//               color: "#000",
//               borderRadius: "8px",
//               boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//             }}
//           >
//             <Typography variant="body1">
//             A serene desert oasis with a clear blue pond, date palms, a small tent, and a camel resting nearby. #DesertOasis #Serenity #TravelGoals #Wanderlust #NatureBeauty
//             </Typography>
//           </Box>
//         </DialogContent>
//       </Box>
//     </BootstrapDialog>
//   );
// }




import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SampleImageModal({ modalImage, setModalImage }) {
  const handleClose = () => setModalImage(false);

  // Preload the image
  const imageUrl = "https://backend.whalesai.com/public/imageai/15082024-267001.png";
  const [imageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setImageLoaded(true);
  }, [imageUrl]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalImage}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Sample</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          {/* Image Section */}
          {imageLoaded ? (
            <img
              src={imageUrl}
              alt="Sample"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', padding: 2 }}>
              Loading...
            </Typography>
          )}

          {/* Text and Hashtag Section */}
          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="body1">
              A serene desert oasis with a clear blue pond, date palms, a small tent, and a camel resting nearby. #DesertOasis #Serenity #TravelGoals #Wanderlust #NatureBeauty
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
