import React from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';

const ContactUs = () => {
    return (
        <div>
            <PageHeading title='Contact us' />
        </div>
    );
};

export default ContactUs;