import React, { useEffect } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import HowItWorksTab from "./HowItWorksTab";
import one from '../../Assets/Images/Video-Poster/01.png'
import two from '../../Assets/Images/Video-Poster/02.png'
import three from '../../Assets/Images/Video-Poster/03.png'
import four from '../../Assets/Images/Video-Poster/04.png'
import five from '../../Assets/Images/Video-Poster/05.png'
import six from '../../Assets/Images/Video-Poster/06.png'
import seven from '../../Assets/Images/Video-Poster/07.png'

import './index.css'
import MetaData from "../../Components/MetaData/MetaData";

const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [tabValue, setTabValue] = React.useState(0);
  const videoLinks = [
    "https://backend.whalesai.com/public/hiwvideos/ConsultantAI.mp4",
    "https://backend.whalesai.com/public/hiwvideos/TextAI.mp4",
    "https://backend.whalesai.com/public/hiwvideos/ImageAI.mp4",
    "https://backend.whalesai.com/public/hiwvideos/PostAI.mp4",
    "https://backend.whalesai.com/public/hiwvideos/TextVideoAI.mp4",
    "https://backend.whalesai.com/public/hiwvideos/newsvideo.mp4",
    "https://backend.whalesai.com/public/hiwvideos/comicvideo.mp4",
  ];
  const poster = [one, two, three, four, five, six, seven];
  // const div

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  console.log(tabValue);

  return (
    <div>
           <MetaData
        pageTitle={
          "Best Generative AI Tool for Beginners | Easy Generative AI"
        }
        pageDescription={
          "Find the best generative AI tools for beginners to create text, images, audio, and more. Enjoy user-friendly platforms designed for easy learning and creativity."
        }
        canonical="https://whalesai.com/how-it-works"
      ></MetaData>
      <PageHeading title={"HOW IT WORKS"} />
      <div className="container account-container pb-3">
        <HowItWorksTab tabValue={tabValue} handleChangeTab={handleChangeTab} />

        <div
          style={{ marginBlock: "20px" }}
          className="d-flex justify-content-center mx-auto video-container"
        >
          {/* "https://i.ibb.co/kqxs8Bw/companin.jpg" */}
          <video
            controls
            playsInline
            poster={poster[tabValue]}
            src={videoLinks[tabValue]}
            className=""
            style={{ width: "90%" }}
            type="video/mp4"
          />
        </div>

        {/* <div
          style={{ marginBlock: "20px", height: "200px", width: "100%" }}
          className="d-flex justify-content-center "
        >
          {tabValue === 0 && (
            // <img  className="img-fluid" src="/c3.jpeg" alt="companion ai"></img>
            <video
              controls
              playsInline
              poster="https://i.ibb.co/kqxs8Bw/companin.jpg"
              src="/companionai.mp4"
              className=""
              style={{ width: "90%" }}
              type="video/mp4"
            />
          )}

          {tabValue === 1 && (
            <img className="img-fluid" src="/t3.jpeg" alt="text ai"></img>
            // <video
            //   controls
            //   playsInline
            //   poster="https://i.ibb.co/C5XFSMM/textai.jpg"
            //   src="/textai.mp4"
            //   className=""
            //   style={{ width: "90%" }}
            //   type="video/mp4"
            // />
          )}

          {tabValue === 2 && (
            <img className="img-fluid" src="/i3.jpeg" alt="=image ai"></img>
            // <video
            //   controls
            //   playsInline
            //   poster="https://i.ibb.co/Pzk1HzW/imgai.jpg"
            //   src="/imageai.mp4"
            //   className=""
            //   style={{ width: "90%" }}
            //   type="video/mp4"
            // />
          )}
          {tabValue === 3 && (
            <img className="img-fluid" src="/v3.jpeg" alt="text video ai"></img>
            // <video
            //   controls
            //   playsInline
            //   poster={videoAiCover}
            //   src="/videoai.mp4"
            //   className=""
            //   style={{ width: "90%" }}
            //   type="video/mp4"
            // />
          )}
        </div> */}   
     
      </div>
    </div>
  );
};

export default HowItWorks;
