import React from 'react';

const One = () => {
    return (
        <div className='text-white'>
            <h5>Restaurant Reviewer</h5>
            <p className='fw-bold'>Input: Western Restaurant </p>
            <p><span className='fw-bold'>Output</span>: I recently dined at a Western restaurant and I must say, I was thoroughly impressed. From the moment I walked in, the ambiance was warm and inviting. The decor was a perfect blend of rustic and modern, creating a cozy yet trendy atmosphere.</p>


            <h5 className='mt-5'>Ask Anything</h5>
            <p className='fw-bold'>Input: What is artificial intelligence?</p>
            <p><span className='fw-bold'>Output</span>: Artificial intelligence (AI) is a branch of computer science that aims to create intelligent machines that can think and act like humans. AI systems are designed to learn from their environment and experiences, and to use that knowledge to solve problems and make decisions. AI can be used to automate tasks, improve decision-making, and create new products and services.</p>

            <h5 className='mt-5'>Q&A</h5>
            <p className='fw-bold'>Input: What is Blockchain?</p>
            <p><span className='fw-bold'>Output</span>: Blockchain is a decentralized, distributed, and public digital ledger that is used to record transactions across many computers so that any involved record cannot be altered retroactively, without the alteration of all subsequent blocks.</p>
        </div>
    );
};

export default One;