import React from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import Banner from "../Home/Banner/Banner";
import "./API.css";
import MetaData from "../../Components/MetaData/MetaData";

const API = () => {
  
  const text = [
    "Text for Facebook with maximum of 63,206 characters" ,
    "Text for Instagram with maximum of 2.200 characters ",
    "Text for Threads with maximum of 500 characters ",
    "Text for Twitter / X with maximum of 280 characters ",
    "Text for Medium with maximum of 1500 words",
    "Text for Telegram with maximum of 4.906 characters "
  ];

  const content = ["Informative posts","Quotes","Motivational messages","Captivating stories","Poems","Tips and how-to guides","Educational content","Polls and questions","Quizzes and challenges","Articles"];

  const data = [
    {
        category: 'Text Images',
        parameters: [
            { name: 'Api token', value: '' },
            { name: 'Size', value: '' },
            { name: 'Color Picker', value: '' },
            { name: 'Design Type', value: '' },
            { name: 'Font Type', value: '' },
            { name: 'Text', value: '' }
        ]
    },
    {
        category: 'Square Images with Text',
        parameters: [
            { name: 'Api token', value: '' },
            { name: 'Image', value: '' },
            { name: 'Translucent Background Image', value: '' },
            { name: 'Text', value: '' }
        ]
    },
    {
        category: 'Text Videos',
        parameters: [
            { name: 'Api token', value: '' },
            { name: 'Size', value: '' },
            { name: 'Color Picker', value: '' },
            { name: 'Design Type', value: '' },
            { name: 'Font Type', value: '' },
            { name: 'Text', value: '' }
        ]
    },
    {
        category: 'News Videos',
        parameters: [
            { name: 'Api token', value: '' },
            { name: 'Size', value: '' },
            { name: 'Color Picker', value: '' },
            { name: 'Design Type', value: '' },
            { name: 'Font Type', value: '' },
            { name: 'Type of News', value: '' },
            { name: 'Time', value: '' }
        ]
    }
];

const apps = [
  {
      name: 'SocialMediaSuite',
      capabilities: ['Integrates with Facebook, Instagram, Threads, Twitter/X, Medium, and Telegram.', 'Generates diverse content types like informative posts, quotes, motivational messages, and captivating stories.', 'Text, text images, square images with text, and text videos capabilities.']
  },
  {
      name: 'ContentCrafter',
      capabilities: ['Provides content generation for Medium, Facebook, and Twitter/X.', 'Specializes in articles, tips and how-to guides, and educational content.', 'Offers text images and text video features.']
  },
  {
      name: 'InstaBoost',
      capabilities: ['Focuses on Instagram and Threads.', 'Creates captivating stories, poems, and motivational messages.', 'Supports text images and square images with text.']
  },
  {
      name: 'TweetMaster',
      capabilities: ['Tailored for Twitter/X users.', 'Generates concise posts, quotes, and polls.', 'Includes square images with text and text videos.']
  },
  {
      name: 'EducateHub',
      capabilities: ['Specializes in educational content for Medium and Telegram.', 'Produces informative posts, tips and how-to guides, and quizzes.', 'Provides text images and news videos.']
  },
  {
      name: 'QuoteGenie',
      capabilities: ['Focuses on creating quotes and motivational messages for Facebook, Instagram, and Twitter/X.', 'Features text images, square images with text, and text videos.',]
  },
  {
      name: 'StoryTeller',
      capabilities: ['Dedicated to generating captivating stories and poems for Threads, Medium, and Instagram.', 'Offers text and text images.']
  },
  {
      name: 'PollPro',
      capabilities: ['Specializes in creating polls and questions for Twitter/X and Telegram.', 'Generates text and square images with text.']
  },
  {
      name: 'NewsFlash',
      capabilities: ['Focuses on generating news videos for Telegram and Facebook.', 'Provides real-time news updates in various video formats.']
  },
  {
      name: 'ChallengeMe',
      capabilities: ['Creates quizzes and challenges for Instagram, Twitter/X, and Medium.', 'Includes text, text images, and text videos.', ]
  }
];



  return (
    <div>
                 <MetaData
        pageTitle={
          "Best Generative AI Tool for Beginners | Easy Generative AI"
        }
        pageDescription={
          "Find the best generative AI tools for beginners to create text, images, audio, and more. Enjoy user-friendly platforms designed for easy learning and creativity."
        }
        canonical="https://whalesai.com/api"
      ></MetaData>
        <div className="container">
      <PageHeading title="API" description ='Stay tuned for something amazing—our exciting new launch is just around the corner!' />
      
     <div className="container account-container text-white page-content px-4">
       {/* <h3>The following APIs will be available soon.</h3>

       <p className="fw-bold">Text</p> */}

       {/* <ol>
         {
          text.map((item) => <li>
            {item}
          </li>)
         }
       </ol> */}
      
      {/* <p className="fw-bold">Note: Only 80% of the maximum characters or words will be produced.</p> */}

      {/* <h3>Types of contents for Text</h3>
      <ol>
         {
          content.map((item) => <li>
            {item}
          </li>)
         }
       </ol> */}

       {/* <p className="fw-bold">Parameters</p>
       <ol>
        <li>Api token</li>
        <li>Social Medisa</li>
        <li>Type of Content</li>
        <li>Description</li>
       </ol> */}


       {/* <div>
       {data.map((item, index) => (
                <div key={index} className=" mb-4">
                    <div className="">
                        <h3>{item.category}</h3>
                        <p className="fw-bold">Parameters</p>
                    </div>
                    <div className="">
                        <ol className="">
                            {item.parameters.map((param, idx) => (
                                <li key={idx} className="">
                                    <span>{param.name}</span>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            ))}
       </div> */}

      <section className="">
      <div>
        <h3 className="mb-10 title">10 apps that could effectively use the upcoming APIs:</h3>
       {apps.map((app, index) => (
                <div key={index} className=" mb-4">
                    <div className="">
                        <h5>{index + 1} . <span className="title-name">{app.name}</span></h5>
                    </div>
                    <div className="">
                        <ul className="">
                            {app.capabilities.map((capability, idx) => (
                                <li key={idx} className="">
                                    {capability}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
       </div>
      </section>
       

     </div>


    <button className="btn btn-info mb-5 ">SEE WHAT YOU CAN GENERATE WITH OUR API</button>
    </div>
    <Banner/>
    </div>
  );
};

export default API;
