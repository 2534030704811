import React from "react";

const QA = ({ inputText, isGenerating, text, formattedText }) => {
  console.log(inputText, isGenerating, text, formattedText);
  return (
    <>
      <p class="preset-title">Q&A</p>

      {inputText ? (
        <>
          <p class="text-muted">Q: {inputText}</p>
          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
               
                <p
                  style={{
                    color: "black",
                  }}
                  class="text- mb-0 text-"
                >
                  {text}
                </p>
              
            </>
          )}
        </>
      ) : (
        <>
          <p class="text-muted" style={{ fontSize:"14px" }}>Q: What is Blockchain?</p>
          <p
     style={{ color: "black",fontSize:"14px" }}
          >
            A: Blockchain is a decentralized, distributed, and public digital
            ledger that is used to record transactions across many computers so
            that any involved record cannot be altered retroactively, without
            the alteration of all subsequent blocks.
          </p>
        </>
      )}
    </>
  );
};

export default QA;
