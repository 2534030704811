import React, { useContext, useRef } from 'react';
import { useState, useEffect } from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';
import moneyExchangeIcon from '../../Assets/Images/transaction-data.png';
import arrowIcon from '../../Assets/Images/arrow-icon.png';
import './TextAi.css';
import axios from 'axios';
import { Alert, Modal, notification } from 'antd';
import { FaRegCopy } from 'react-icons/fa';
import TextAiPresets from './TextAiPresets';
import BuyCreditsModal from '../BuyCredits/BuyCreditsModal';
import Showdown from 'showdown';
import wordCount from 'word-count';
import copy from 'copy-to-clipboard';

import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { PropagateLoader } from 'react-spinners';
import {
  GSE,
  SFG,
  TTC,
  QA,
  ETOL,
  PUD,
  CLS,
  NLTP,
  EC,
  // CHAT,
  MTE,
  JTP,
  RRC,
  ADS,
  ASK,
  MHSC,
} from './TextAiImportStatement';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import { handleAiCompletion } from '../../utils/AiApiFunctions';
import swal from 'sweetalert';

const TextAi = () => {
  const { AIuser, userRefetch, setUserRefetch } = useContext(UserAuthContext);
  const [preset, setPreset] = useState('');
  const [presetSelected, setPresetSelected] = useState({
    option: '',
    label: '',
  });
  const [generatingtext, setGeneratingText] = useState('');
  const [text, setText] = useState('');
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [inputText2, setInputText2] = useState('');
  const [usingChat, setUsingChat] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [clearQS, setClearQS] = useState(false);
  const [error, setError] = useState(
    "You don't enough credits! Please buy credits to continue."
  );
  const inputRef2 = useRef(null);
  const wordRef = useRef(null);
  const inputRef = useRef(null);
  const divRef = useRef(null);
  const [count, setCount] = useState(0);
  const [words, setWords] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // const [transactionId, setTransactionId] = useState("");

  // useEffect(() => {
  //   // generate a random 8 character alpha numeric string

  //   const randomChars =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655";
  //   let result = "";
  //   for (let i = 0; i < 8; i++) {
  //     result += randomChars.charAt(
  //       Math.floor(Math.random() * randomChars.length)
  //     );
  //   }
  //   setTransactionId(result);
  // }, []);
  // const [showCursor, setShowCursor] = useState(true);

  // useEffect(() => {
  //   const cursorTimer = setInterval(() => {
  //     setShowCursor((prevShowCursor) => !prevShowCursor);
  //   }, 500); // Adjust the blinking interval as desired

  //   return () => {
  //     clearInterval(cursorTimer);
  //   };
  // }, []);

  // buy credits modal close

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Insufficient Credits',
      description:
        'Your remaining credits are getting low. Please buy more credits to avoid interruptions.',
    });
  };

  function countWords(text) {
    // split the text into an array of words
    if (text == '') {
      const wordCount = 0;
      return wordCount;
    }
    const words = text?.split(
      /[\s\u0BB7\u0BCD\u0BB0\u0BBE\u0BA4\u0BCD\u0BA4\u0BC8\u0BB5\u0BBF\u0BB5\u0BC1\u0BAE\u0BCD\u0BAE\u0BA3\u0BCD\u0B9F\u0BCD\u0B9F\u0BA9\u0BCD\u0B9A\u0BCD\u0B9A\u0BB0\u0BCD]/
    );
    // count the length of the words array
    const wordCount = words?.length;
    return wordCount;
  }


  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      // console.log("User is scrolling down");
      setIsScrolling(false);
    } else if (event.deltaY < 0) {
      // console.log("User is scrolling up");
      setIsScrolling(true);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    // if the text is generating then run the below code
    if (isGenerating === true) {
      const handleScroll = () => {
        const scrollTop = inputRef?.current?.scrollTop;
        setIsScrollingUp(scrollTop < inputRef?.current?._prevScrollTop);
        inputRef.current._prevScrollTop = scrollTop;
      };

      inputRef.current._prevScrollTop = 0;
      inputRef.current.addEventListener('scroll', handleScroll);
      const ref = inputRef.current;

      return () => {
        if (ref) {
          ref.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [generatingtext, isGenerating]);

  useEffect(() => {
    if (isScrolling === false && isScrollingUp === false) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  }, [text, isScrolling, isScrollingUp]);

  useEffect(() => {
    const worker = new Worker('./worker.js');

    worker.addEventListener('message', (event) => {
      const { text, isDone } = event.data;

      // setIsDoneGenerating(isDone);
      if (text !== 'Processing...') {
        setText(generatingtext);
      }
     
      // if () {
      // }
      // if (presetSelected.option === 'CHAT') {
      //   setMessages([...messages, { author: 'ai', body: text }]);
      //   setInputText('');
      // }

      inputRef.current.focus();
      setCount(countWords(generatingtext));
    });

    worker.postMessage({ generatingtext, words });
    // setShowCursor(prevShowCursor => !prevShowCursor);

    return () => {
      worker.terminate();
    };
  }, [generatingtext, isGenerating]);

  const handleSubmitCredits = async (wordCount) => {
    const deductedCredits = Math.floor(AIuser.credits - wordCount/10);

    console.log('creditsssssssssssssss => :', deductedCredits, wordCount/100)
    // setInputText('')

    // console.log("question", question, conversation);
    await axios
      .put(
        'https://backend.whalesai.com/api/dashboard/user_signup-signin/update-credits',
        { email: AIuser?.email, credits: deductedCredits },
        {
          headers: {
            authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserRefetch(!userRefetch);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearInput2 = () => {
    document.getElementById("inputText").value = "";
    setInputText("");
  }; 


  const countWordsResponse = (str) => {
    return str.trim().split(/\s+/).filter(Boolean).length;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setClearQS(true);
      divRef.current.scrollIntoView({ behavior: 'smooth' });
      localStorage.setItem('inputText', inputText);
      setInputText2('')
      if (parseInt(AIuser.credits) >= 10 && parseInt(AIuser.credits) <= 30) {
        openNotificationWithIcon('warning');
      }

      if (parseInt(AIuser.credits) >= 10) {
        setIsGenerating(true);
        setGeneratingText(() => '');
        setText('');
        setIsScrolling(false);
        setIsScrollingUp(false);
        setShowAlert(false);

        const randomChars =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655';
        let transactionId = '';
        for (let i = 0; i < 8; i++) {
          transactionId += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
          );
        }
        // --------------
        let question;
        if (presetSelected.option === 'GSE') {
          question = `Correct the following sentence to standard English::\n\n${
            !inputText ? 'She no went to the market.' : inputText
          }`;
        } else if (presetSelected.option === 'SFG') {
          question = `Summarize this for a second-grade student:\n\n ${
            inputText ? inputText : presetSelected.label
          }`;
        } else if (presetSelected.option === 'ASK') {
          question = inputText;
        } else if (presetSelected.option === 'ETOL') {
          question = `Translate the text to tamil:${inputText}`;
        } else if (presetSelected.option === 'PUD') {
          question = `summarizing the text in a table format:\n\n ${
            inputText ? inputText : presetSelected.label
          }`;
        } else if (presetSelected.option === 'TTC') {
          question = `Convert this text to a command:\n\n${inputText}`;
        } else if (presetSelected.option === 'QA') {
          question = `Give the answer of Q: in A: format. Q:${inputText}`;
        } else if (presetSelected.option === 'CLS') {
          question = `Give the category of the below text in Category: format:${inputText}`;
        } 
        // else if (presetSelected.option === 'CHAT') {
        //   setMessages([...messages, { author: 'human', body: inputText }]);

        //   const prompt = messages.map((message) => message.body).join('\n');
        //   question = `${prompt}\nHuman: ${inputText}\nAI:`;
        // } 
        else if (presetSelected.option === 'EC') {
          question = `${inputText}\n\n\n\nHere's what the above code is doing(explain in bullet points):\n`;
        } else if (presetSelected.option === 'NLTP') {
          question = `\n${inputText}\n\nWrite python code according to the above:\n`;
        } else if (presetSelected.option === 'MTE') {
          question = `Convert the movie title into emoji: ${inputText}:\n`;
        } else if (presetSelected.option === 'JTP') {
          question = `#JavaScript to Python:\nJavaScript: \n${inputText}`;
        } else if (presetSelected.option === 'RRC') {
          question = `Write a restaurant review based on these notes:\n\n${inputText}\n\nReview:`;
        } else if (presetSelected.option === 'ADS') {
          question = `Write a creative ad for the following product to run on Facebook:\n\n${inputText}`;
        } else if (presetSelected.option === 'MHSC') {
          question = `Topic: ${inputText}\nTwo-Sentence Horror Story:`;
        }

        console.log('question', question)
        console.log('question', presetSelected.option)

        const response = await handleAiCompletion(
          question,
          presetSelected.option
        );

        if (response) {
          const wordCount = countWordsResponse(response)

          console.log('total word counttttttttttttttttttttt ================================================> ::::F::', wordCount)
          handleSubmitCredits(wordCount);
          setGeneratingText(response);
          const transactionRes = await axios.post(
            'https://backend.whalesai.com/api/v1/credits-transaction/create',
            {
              aiType: 'Text Ai',
              transactionId: transactionId,
              requestQues: inputText,
              response: response,
              credit:  Math.floor(wordCount/10),
              userId: AIuser?._id,
              textAiType: presetSelected.option,
            }
          );
          setIsGenerating(false);
          // setInputText('');
          // if(!isGenerating){
          //   handleClearInput2();
          // }
          
        } else {
          setGeneratingText('Invalid prompt!');
        }
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      setIsGenerating(false);
      swal({
        text: 'Something went wrong! Please try again.',
        icon: 'error',
        button: 'Ok',
        className: 'modal_class_success',
      });
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      setPresetSelected({
        option: location.state.textAiType,
      });
      setGeneratingText(location.state.response);
      setInputText(location.state.requestQues);
    }
  }, [location.state]);

  const [formattedText, setFormattedText] = useState('');

  useEffect(() => {
    // split the text into lines
    if (text !== 'Processing...') {
      const lines = text?.split('\n');

      // add each line to the formatted text with a newline
      const formattedLines = lines?.map((line, index) => (
        <div key={index}>
          {line}
          <br />
        </div>
      ));

      setFormattedText(formattedLines);
      // if (!(presetSelected.option === 'CHAT')) {
      //   console.log('in to the chat');
      //   setText(text);
      // }
    }
  }, [text]);

  // const handleClearInput = () => {
  //   document.getElementById("inputText").value = "";
  //   setInputText("");
  // };
  const handleClearData = () => {
    setMessages([]);
    setFormattedText([]);
    setInputText('');
    setInputText2('');
    setGeneratingText('');
    setIsGenerating(false);
    // console.log("clearing");
    setText('');
    // }
    // if (presetSelected.option === 'CHAT') {
    //   setUsingChat(false);
    // }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      inputText !== '' &&
      !event.shiftKey &&
      window.innerWidth > 1000
    ) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event);
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault(); // prevent the default enter behavior
      setInputText(inputText + '\n'); // add a new line to the input value
    }

    if (window.innerWidth < 1000 && event.key === 'Enter') {
      event.preventDefault(); // prevent the default enter behavior
      setInputText(inputText + '\n'); // add a new line to the input value
    }
  };

  const converter = new Showdown.Converter({
    tables: true,
    simpleLineBreaks: true,
  });

  let html;
  if (presetSelected.option === 'PUD') {
    html = converter.makeHtml(text).replace(/<table/g, '<table class="table"');
  }

  useEffect(() => {
    const countWords = () => {
      if (wordRef.current) {
        if (presetSelected.option !== 'ETOL') {
          const numberOfWords = wordCount(wordRef.current.innerText);

          setWords(numberOfWords);
        } else {
          const numberOfWords = wordCount(wordRef.current.innerText);
          const tamilRegex = /[\u0B80-\u0BFF]+/g;
          const tamilwords = wordRef.current.innerText.match(tamilRegex);
          const wordCounts = tamilwords ? tamilwords.length : 0;
          setWords(numberOfWords + wordCounts);
        }
      }
    };
    countWords();
  }, [wordRef, inputText, text, presetSelected.option]);

  const handleCopy = () => {
    const title = document.querySelector('.preset-title').innerText;
    const contents = document
      .querySelector('.copy-contents')
      .innerText.replace(title, '');

    const isCopied = copy(contents);
    if (isCopied) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }
  };

  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";

  console.log('dddddd selected ===> :', presetSelected)
  console.log('texxxxxxt selected ===> :', text)

  return (
    <>
      <div className="h-100 companion-ai-container mb-4">
        <PageHeading title={'TEXT AI'} description={'The Text AI aids in generating content for various social media platforms.'} />

        <div className="container companion-ai-main">
        <div           
          style={{
            maxWidth: "872px",
            paddingInline: "12px",
            marginInline: "auto",
          }}>
          <div
          //  className="CreditDiv"
           className="d-flex align-items-center justify-content-center centralize-credits-btn-sm mt-2"
          >
            <button className="credits-button">
              CREDITS : {creditTolcalString}
            </button>
            <button
              className="buy-credits-button"
              onClick={() => navigate('/purchase')}
            >
              BUY CREDITS
            </button>

            <button
              onClick={() => navigate('/transactions')}
              className="money-exchange-button"
            >
              <img src={moneyExchangeIcon} alt="image" />
            </button>
          </div>

          <p className="text-white mb-0 mt-2 creditText text-center">
            1 Credit per 10 words
          </p>
          <div className="selectDiv">
            <TextAiPresets
              setPreset={setPreset}
              preset={preset}
              setPresetSelected={setPresetSelected}
              text={text}
              inputText={inputText}
              setInputText={setInputText}
              setText={setText}
              setGeneratingText={setGeneratingText}
              setMessages={setMessages}
              setUsingChat={setUsingChat}
            />
          </div>

          <div className="first-field-container" ref={divRef}>
            <div
              style={{
                backgroundColor: '#3080AC',
                paddingInline: '16px',
                paddingBlock: '17px',
                borderRadius: '4px 4px 0px 0px',
              }}
              className="d-flex justify-content-between align-items-center text-white"
            >
              <h5 style={{ fontWeight: '400' }} className="mb-0">
                WORD COUNT : {words}
              </h5>
              <div className="d-flex gap-3">
                {showTooltip ? (
                  <p className="mb-0" style={{ fontSize: '13px' }}>
                    Copied!
                  </p>
                ) : (
                  <FaRegCopy
                    onClick={handleCopy}
                    className=""
                    // size={20}
                    style={{ cursor: 'pointer' }}
                  />
                )}

                {/* <i
                  class="fa-regular fa-circle-xmark"
                  style={{ cursor: 'pointer' }}
                  onClick={handleClearData}
                ></i> */}
              </div>
            </div>

            <div
              id="responseDiv"
              className="fist-text-area"
              onWheel={handleScroll}
              ref={inputRef}
            >
              {showAlert == false && error !== '' ? (
                <>
                  {preset ? (
                    <div class="media-body ml-3">
                      <div
                        className="text-light rounded py-2 mb-2 text-start ask-ai-fonts copy-contents"
                        ref={wordRef}
                      >
                        {presetSelected?.option === 'GSE' ? (
                          <GSE
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                          />
                        ) : presetSelected?.option === 'SFG' ? (
                          <SFG
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                          />
                        ) : presetSelected?.option === 'TTC' ? (
                          <TTC
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                          />
                        ) : presetSelected?.option === 'QA' ? (
                          <QA
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            formattedText={formattedText}
                          />
                        ) : presetSelected?.option === 'ETOL' ? (
                          <ETOL
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            wordRef={wordRef}
                          />
                        ) : presetSelected?.option === 'PUD' ? (
                          <PUD
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                            html={html}
                            wordRef={wordRef}
                          />
                        ) : presetSelected?.option === 'CLS' ? (
                          <CLS
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                          />
                        ) : presetSelected?.option === 'NLTP' ? (
                          <NLTP
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                          />
                        ) : presetSelected?.option === 'EC' ? (
                          <EC
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                          />
                        ) : presetSelected?.option === 'ASK' ? (
                          <ASK
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            formattedText={formattedText}
                          />
                        ) : presetSelected?.option === 'MTE' ? (
                          <MTE
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                            wordRef={wordRef}
                          />
                        ) : presetSelected?.option === 'JTP' ? (
                          <JTP
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                          />
                        ) : presetSelected?.option === 'RRC' ? (
                          <RRC
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                            wordRef={wordRef}
                          />
                        ) : presetSelected?.option === 'ADS' ? (
                          <ADS
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                            wordRef={wordRef}
                          />
                        ) : presetSelected?.option === 'MHSC' ? (
                          <MHSC
                            inputText={inputText}
                            isGenerating={isGenerating}
                            text={text}
                            presetSelected={presetSelected}
                            wordRef={wordRef}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center mx-auto">
                      {' '}
                      <PropagateLoader
                        color="white"
                        loading
                        size={window.innerWidth <= 700 ? 10 : 15}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Alert message={error} type="error" showIcon />
                </>
              )}
            </div>
          </div>
          <div className="second-field-container position-relative">
            <form className="text-ai-rep-form" onSubmit={handleSubmit}>

              <textarea
                style={{
                  padding: '10px',
                }}
                className="second-text-area"
                id="inputText"
                name="inputBox"
                cols="30"
                rows="3"
                value={inputText2}
                onKeyDown={handleKeyDown}
                ref={inputRef2}
                onChange={(e) => {
                  setInputText(e.target.value);
                  setInputText2(e.target.value);
                  setUsingChat(true);
                  setText("");
                  setGeneratingText("");
                }}
              ></textarea>   

              <div className="mt-4 textGCButton text-center w-100">
                  <button
                  onClick={handleClearData}
                  type='button'
                  className="clear-button rounded">Clear</button>
                  <button
                  onClick={handleSubmit}
                    disabled={inputText.length === 0}
                     style={inputText.length === 0?{backgroundColor:"gray"}:{}}
                  className="generate-button rounded">Generate</button>
            </div>
            </form>
          </div>
          <div className='animate text-md-center mt-4 text-lg-center text-center d-flex justify-content-center mb-5'>Please do not navigate away from the browser.<br/> Doing so will stop the process.</div>
        </div>
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default TextAi;


